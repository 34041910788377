<template>
  <div>
    <GeneralTableTabs :tabsList="tabsList"
      @selectTip="handleSelect" />
    <GeneralTable ref="GeneralTable"
      tableName="ClauseDocumentTable"
      :dataList="dataList"
      :column="column"
      :option="option"
      :totalNum="totalNum"
      @add-change="handleEdit('add',{})"
      @search-change="handleSearch"
      @del-change="handleDel"
      @export-data="handleExport">
      <template slot="operate"
        slot-scope="{ item }">
        <el-button type="text"
          @click="handleViewDoc(item)">查看内容</el-button>
        <el-button type="text"
          @click="handleEdit('edit',item)">编辑</el-button>
        <el-button type="text"
          @click="handleDel([item])">删除</el-button>
      </template>
    </GeneralTable>
    <addOrEditDocDialog :show.sync="addEditShow"
      :dict="dict"
      :category="curType"
      :itemData="currentItem"
      @submit="$refs.GeneralTable.getList()" />
    <!-- <docViewDialog :itemData="currentItem"
      :show.sync="docShow" /> -->
  </div>
</template>

<script>
import { dictionaryBatch, getClauseList, deletClauseLDoc, exportClauseLDoc, getInsuranceDocTypeCount } from "@/api/policy";
import GeneralTable from "@/components/GeneralTable/index.vue";
import GeneralTableTabs from "@/components/GeneralTable/GeneralTableTabs.vue";
import addOrEditDocDialog from "./components/addOrEditDocDialog.vue";
import editTipsDialog from "./components/editTipsDialog.vue";
//import docViewDialog from "./components/docViewDialog.vue";
export default {
  name: 'ClauseDocument',
  components: { GeneralTable, GeneralTableTabs, addOrEditDocDialog, editTipsDialog },
  data() {
    return {
      curType: 1,
      totalNum: 0,
      addEditShow: false,
      editTipsShow: false,
      docShow: false,
      currentItem: null,
      tabsList: [{ text: '工程机械', value: 1, count: 0 }, { text: '车险', value: 2, count: 0 }, { text: '非设备类财产险', value: 3, count: 0 }],
      dict: {},
      dataList: [],
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "名称",
          prop: "termsDocumentsName",
          width: 280,
          isShow: true,
        },
        {
          label: "保险公司",
          prop: "companyName",
          width: 280,
          isShow: true,
        },
        {
          label: "所属产品条款",
          prop: "termConfigurationName",
          width: 280,
          isShow: true,
        },
        {
          label: "录入员",
          prop: "enteredName",
          width: 280,
          isShow: true,
        },
        {
          label: "创建时间",
          prop: "createTime",
          width: 280,
          isShow: true,
        },
        {
          label: "更新时间",
          prop: "updateTime",
          width: 280,
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 220,
          fixed: "right",
          isSlot: true,
        },
      ],
      option: {
        isAdd: true, //添加 事件 @add-change
        isDel: true, //删除 @del-change
        isEdit: true, // 编辑 @edit-change
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        isExport: true,
        pageSize: 10,
        keyword: 'fastBlur',
        searchList: [
          {
            label: "名称",
            prop: "termsDocumentsName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "保险公司",
            prop: "companyName",
            formType: "select",
            filterable: true,
            returnText: true,
            placeholder: "请选择",
            selectList: [],
          },
          {
            label: "所属保险条款",
            prop: "termConfigurationName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "录入员",
            prop: "enteredName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "创建时间",
            formType: "daterange",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            dateFormat: true,
            prop: ["createTimeBegin", "createTimeEnd"],
          },
          {
            label: "更新时间",
            formType: "daterange",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            dateFormat: true,
            prop: ["updateTimeBegin", "updateTimeEnd"],
          }
        ],
      },
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    curType(n) {
      this.getList();
    }
  },
  methods: {
    init() {
      dictionaryBatch({ codes: 'insuranceCompany' }).then(res => {
        this.dict = res.data;
        this.option.searchList.find(sc => {
          return sc.prop == 'companyName';
        }).selectList = this.dict.insuranceCompany.map(dic => {
          return {
            value: dic.dictKey,
            text: dic.dictValue
          };
        });
      });
      this.getList();
    },
    getList(data = {}) {
      let param = { category: this.curType, ...data };
      getInsuranceDocTypeCount(data, { current: data.pageNum, size: data.pageSize }).then(res => {
        this.tabsList.forEach(item => {
          res.data.forEach(coun => {
            if (coun.category == item.value) {
              item.count = coun.count;
            }
          });
        });
      });
      getClauseList(param).then(res => {
        this.dataList = res.data.records;
        this.totalNum = res.data.total;
      });
    },
    handleSearch(data) {
      this.getList(data);
    },
    handleSelect(item) {
      this.curType = item.value;
    },
    handleEdit(type, item = null) {
      this.currentItem = item;
      if (type == 'add' || !item.termConfigurationName) {
        this.addEditShow = true;
      } else {
        const h = this.$createElement;
        this.$msgbox({
          title: '编辑',
          type: 'warning',
          message: h('p', null, [
            h('span', null, '编辑此条款将会影响到 '),
            h('span', { style: 'color: #0080FFFF' }, item.termConfigurationName),
            h('span', null, '是否继续？ '),
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(action => {
          if (action == 'confirm') {
            this.addEditShow = true;
          }
        });
      }
    },
    handleDel(data) {
      if (data.length) {
        this.$confirm(`是否删除`, '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = data.map(item => {
            return item.id;
          });
          deletClauseLDoc(ids).then(res => {
            this.$refs.GeneralTable.getList();
          });
        });
      }
    },
    handleExport(data) {
      let ids = data.map(item => {
        return item.id;
      });
      if (ids.length) {
        exportClauseLDoc(ids);
      }

    },
    handleViewDoc(item) {
      // this.currentItem = item;
      // this.docShow = true;
      window.open(item?.attachmentAddReqList[0]?.attachmentUrl, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
</style>