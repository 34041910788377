<template>
  <el-dialog
    destroy-on-close
    :visible.sync="inShow"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title">
      {{ formData.id ? "编辑" : "添加" }}
    </div>
    <div class="content">
      <el-form
        ref="formData"
        :rules="rules"
        :model="formData"
        label-position="left"
        label-width="80px"
      >
        <el-form-item label="名称" prop="termsDocumentsName" required>
          <el-input
            v-model="formData.termsDocumentsName"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="保险公司" prop="companyValue" required>
          <el-select v-model="formData.companyValue" placeholder="请选择">
            <el-option
              v-for="item in dict.insuranceCompany"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item prop="TDlist" class="none-left" required>
          <FormListUpload
            required
            title="条款文件"
            tips="（只支持 word, pdf 格式，单个文件不超过2MB，最多上传1个）"
            :limit="1"
            attachmentType="TD"
            :limitType="['doc', 'docx', 'pdf']"
            v-model="formData.TDlist"
          ></FormListUpload>
        </el-form-item>
        <el-form-item prop="TDIMAGEList" class="none-left" required>
          <FormListUpload
            required
            title="条款图片"
            tips="（只支持 pdf, jpg, png, jpeg 格式，单个文件不超过2MB，最多上传10个）"
            :limit="10"
            attachmentType="TD_IMAGE"
            :limitType="['pdf', 'jpg', 'png', 'jpeg']"
            v-model="formData.TDIMAGEList"
          ></FormListUpload>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer" class="dl-btn-text">
      <el-button @click="reset">重置</el-button>
      <el-button class="dl-confirm-btn" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormListUpload from "@/components/FormListUpload";
import { addClauseLDoc } from "@/api/policy";
export default {
  name: "addOrEditDocDialog",
  //   model: {
  //     event: 'update',
  //     prop: 'show'
  //   },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isedit: {
      type: Boolean,
      default: false,
    },
    itemData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    dict: {
      type: Object,
      default: () => {},
    },
    category: {
      type: [String, Number],
      default: "",
    },
  },
  components: { FormListUpload },
  data() {
    var validateArr = (rule, value, callback) => {
      if (!value?.length || !value) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    };
    return {
      inShow: false,
      formData: {
        TDlist: [],
        TDIMAGEList: [],
        attachmentAddReqList: [],
        companyCode: "insuranceCompany",
        companyValue: "",
        companyName: "",
        termsDocumentsName: "",
      },
      oldData: {
        TDlist: [],
        TDIMAGEList: [],
        attachmentAddReqList: [],
        companyCode: "insuranceCompany",
        companyValue: "",
        companyName: "",
        termsDocumentsName: "",
      },
      rules: {
        termsDocumentsName: [
          {
            required: true,
            message: "请输入名称",
            trigger: ["change", "blur"],
          },
        ],
        companyValue: [
          {
            required: true,
            message: "请选择保险公司",
            trigger: ["change", "blur"],
          },
        ],
        TDlist: [
          {
            validator: validateArr,
            message: "请选择条款文件",
            trigger: ["change", "blur"],
          },
        ],
        TDIMAGEList: [
          {
            validator: validateArr,
            message: "请选择条款文件图片",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  watch: {
    //  itemData: {
    //    //immediate: true,
    //    handler(n) {
    //      n.TDlist = n?.attachmentAddReqList?.filter(item => {
    //        return item.attachmentType == 'TD';
    //      });
    //      n.TDIMAGEList = n?.attachmentAddReqList?.filter(item => {
    //        return item.attachmentType == 'TD_IMAGE';
    //      });
    //      this.formData = n;
    //    }
    //  },
    show(n) {
      this.inShow = n;
      this.oldData = JSON.parse(JSON.stringify(this.itemData));
      this.fillData(this.itemData);
    },
    inShow(n) {
      this.$emit("update:show", n);
    },
  },
  methods: {
    close() {
      this.formData = {};
    },
    fillData(n) {
      let data = JSON.parse(JSON.stringify(this.itemData));
      data.attachmentAddReqList = n?.attachmentAddReqList?.map((item) => {
        return { ...item, url: item.attachmentUrl };
      });
      data.TDlist = data?.attachmentAddReqList?.filter((item) => {
        return item.attachmentType == "TD";
      });
      data.TDIMAGEList = data?.attachmentAddReqList?.filter((item) => {
        return item.attachmentType == "TD_IMAGE";
      });
      this.formData = data;
    },
    reset() {
      this.fillData(this.oldData);
    },
    submit() {
      this.formData.category = this.category;
      this.$refs.formData.validate((valid) => {
        if (valid) {
          this.formData.companyName =
            this.dict.insuranceCompany.find((item) => {
              return item.dictKey == this.formData.companyValue;
            })?.dictValue || "";
          this.formData.attachmentAddReqList = [
            ...this.formData.TDlist,
            ...this.formData.TDIMAGEList,
          ].map((item) => {
            return { ...item, attachmentUrl: item.url };
          });
          addClauseLDoc(this.formData).then((res) => {
            this.inShow = false;
            this.$emit("submit");
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/style/Standard.scss";
::v-deep .el-dialog__footer {
  box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px 8px 8px 8px;
}
.content {
  max-height: 400px;
  overflow-y: auto;
}
.content::-webkit-scrollbar {
  display: none;
}
</style>
