<template>
  <div class="type-select">
    <div v-for="(tab,ti) in tabsList"
      :key="ti"
      :class="['item',curType == tab.value?'active':'']"
      @click="handleSelect(tab)">
      {{tab.text}}<span v-if="tab.count">({{tab.count}})</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeneralTableTabs',
  props: {
    tabsList: {
      type: Array,
      default: () => {
        //[{text:'',value:0}]
        return [];
      }
    }
  },
  data() {
    return {
      curType: ''
    };
  },
  created() {
    if (this.tabsList.length) {
      this.curType = this.tabsList[0].value;
    }
  },
  methods: {
    handleSelect(item) {
      this.curType = item.value;
      this.$emit('selectTip', item);
    }
  }
}
</script>

<style lang="scss" scoped>
.type-select {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: -10px;
  border-radius: 10px 10px 0 0;
  background-color: #fff;
  .item {
    position: relative;
    padding: 13px 20px;
    color: #606266;
    margin-right: 20px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: #409eff;
    }
  }
  .active {
    color: #4278c9;
    font-weight: bold;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      border-radius: 4px;
      background-color: #4278c9;
    }
  }
}
</style>