<template>
  <el-dialog class="tip-dialog"
    :visible.sync="show">
    <div slot="title">
      编辑
    </div>
    <span>编辑此条款将会影响到 条款-4 是否继续</span>
    <div slot="footer"
      class="dl-btn-text">
      <el-button>取消</el-button>
      <el-button class="dl-confirm-btn">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'editTipsDialog',
  model: {
    event: 'update',
    prop: 'show'
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    itemData: {
      type: Object,
      default: () => {
        return {};
      }
    },
  },
  watch: {
    show(n) {
      this.$emit('update', n);
    }
  }

}
</script>

<style lang="scss" scoped>
@import 'src/style/Standard.scss';
</style>